import { template as template_eaa7766c3db14b24b034fad2a002fa3a } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_eaa7766c3db14b24b034fad2a002fa3a(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
