import { template as template_15b3c72e59ee4c30b0609e66eeb4fe36 } from "@ember/template-compiler";
const FKControlMenuContainer = template_15b3c72e59ee4c30b0609e66eeb4fe36(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
