import { template as template_bc2bc54e91904e9e9c9915c9a3dc5d26 } from "@ember/template-compiler";
const FKLabel = template_bc2bc54e91904e9e9c9915c9a3dc5d26(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
